import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: 'es',
	messages: {
		es: {
			welcome_to: 'Bienvenidos a',
			login: 'Iniciar sesión',
			operation: 'Operación',
			user: 'Usuario',
			password: 'Contraseña',
			continue: 'Continuar',
			enter_operation: 'Ingrese una operación',
			user_not_found: 'Usuario o contraseña incorrectos.',
			password_incorrect: 'Contraseña incorrecta.',
			password_expired: 'La contraseña actual ha caducado, debes cambiarla.',
			password_used_recently: 'La nueva contraseña ya se utilizó recientemente.',
			license_active: 'Debes tener al menos una licencia activa.',
			change_password_successfully: 'Contraseña cambiada correctamente.',
			password_incorrect_5: 'Contraseña incorrecta más de 5 veces.',
			accept: 'Aceptar',
			cancel: 'Cancelar',
			close: 'Cerrar',
			refresh: 'Refrescar',
			delete: 'Eliminar',
			download: 'Descargar',
			restore_password: 'Restablecer contraseña',
			password_expired_change: 'Tu contraseña ha expirado, por favor ingresa una nueva.',
			back: 'Regresar',
			password_new: 'Contraseña nueva',
			password_old: 'Contraseña anterior',
			password_confirm: 'Confirmar contraseña',
			password_change: 'Cambiar contraseña',
			password_confirm_please: 'Por favor confirma tu contraseña correctamente.',
			back_please_enter: 'Por favor vuelve atrás e ingresa usuario y operación.',
			accept_logout: '¿Estás seguro de que deseas cerrar sesión?',
			file_manager: 'Gestor de archivos',
			audit_log: 'Registro de auditoría',
			documents: 'Documentos',
			images: 'Imágenes',
			videos: 'Videos',
			audios: 'Audios',
			others: 'Otros',
			search: 'Buscar',
			action: 'Acción',
			been_problem: 'Ocurrió un problema.',
			name: 'Nombre',
			date: 'Fecha',
			file_download: 'Descargar archivo',
			file_info: 'Información de archivo',
			file_upload: 'Subir archivo',
			file_upload_please: 'Por favor subir archivo',
			file_select: 'Seleccione un archivo.',
			folder_select: 'Seleccione una carpeta.',
			file_delete_confirm: '¿Estás seguro de que deseas eliminar el archivo?',
            files_delete_confirm: '¿Estás seguro de que deseas eliminar los archivos seleccionados?',
			folder_access_confirm: 'Estás a punto de dar acceso público a toda la carpeta. ¿Estás seguro de realizar esta acción?',
            file_access_confirm: "Estás a punto de dar acceso a público a este archivo. ¿Seguro quieres realizar esta acción?",
			file_download_successfully: 'Archivo descargado correctamente.',
			folder_create: 'Crear carpeta',
			folder_new: 'Nueva carpeta',
			folder_no_title: 'Carpeta sin título',
			size: 'Tamaño',
			access: 'Acceso',
			updated_at: 'Última modificación',
			public: 'Público',
			restricted: 'Restringido',
			give_public_access: 'Dar acceso público',
			copy_public_url: 'Copiar URL pública',
			delete_successfully: 'Eliminado correctamente.',
			delete_90_days: 'El archivo solo podrá ser eliminado después de 90 días.',
			delete_90_days_folder: 'Al menos un archivo no cumple con la restricción de 90 días.',
			been_problem_delete_file: 'Ocurrió un error al eliminar el archivo.',
			file_upload_successfully: 'El archivo se ha cargado correctamente.',
			been_problem_upload_file: 'Ocurrió un problema al cargar el archivo.',
			file_name_not_empty: 'El nombre de la carpeta no puede estar vacío.',
			folder_create_successfully: 'La carpeta se ha creado satisfactoriamente.',
			been_problem_create_file: 'Ocurrió un problema al crear la carpeta.',
			date_ini: 'Fecha de inicio',
			date_end: 'Fecha de fin',
			has_uppercase: 'La contraseña debe contener al menos una letra mayúscula.',
			has_lowercase: 'La contraseña debe contener al menos una letra minúscula.',
			has_number: 'La contraseña debe contener al menos un número.',
			has_special_char: 'La contraseña debe contener al menos un carácter especial permitido: $, %, &, @, *, #, ?',
			is_valid_length: 'La longitud de la contraseña debe estar entre 12 y 24 caracteres.',
            caution_uppercase: 'PRECAUCIÓN',
            folder_access_successfully: 'La carpeta cambió a acceso público con éxito.',
            global_search_on: "Al presionar, esta búsqueda se aplicará sólo al directorio actual",
            global_search_off: "Al presionar, esta búsqueda se aplicará a todos los directorios",
            longer_expected: "Ten en cuenta que este proceso podrá tardar más de lo esperado.",
            restrict_access: "Restringir acceso",
            public_url_copied: "La URL fue copiada exitosamente en el portapapeles",
            invalid_url: "URL no válida o formato no soportado",
            add: "Agregar",
            selection_multiple: "Selección",
            select_files_to_download: "Por favor selecciona los archivos a descargar",
            select_files_to_delete: "Por favor selecciona los archivos a eliminar",
            file_newer_than_90_days: "El archivo no puede ser eliminado porque tiene menos de 90 días.",
            retry_delete: "Reintentar eliminación",
            files_selected: "Archivos seleccionados",
            batch: "Lote",
            of: "de",
            files: "archivos",
            files_per_batch: "archivos por lote",
            delete_batch: "Eliminar lote",
            deleting: "Eliminando",
            been_problem_download_file: "Ocurrió un problema al descargar el archivo.",
            longer_expected_download_file: "Este proceso puede tardar unos minutos.",
            drop_files_here: 'Arrastra archivos aquí o haz clic para seleccionar',
            file_too_big: 'El archivo es demasiado grande ({{filesize}}MB). Tamaño máximo: {{maxFilesize}}MB',
            invalid_file_type: 'No puedes subir archivos de este tipo',
            upload_failed: 'Error al subir el archivo',
            max_files_exceeded: 'Has excedido el número máximo de archivos',
            remove_file: 'Eliminar archivo',
            cancel_upload: 'Cancelar subida',
            allowed_files: 'Archivos permitidos',
            max_file_size: 'Tamaño máximo por archivo',
            max_total_size: 'Tamaño máximo total',
            max_files: 'Máximo número de archivos simultáneos',
            upload: 'Subir',
            total: 'en total',
            total_size_exceeded: 'El tamaño total de los archivos excede el límite de 150MB',
            uploaded_successfully: 'Archivos cargados correctamente',
            processing: 'Procesando',
            some_files_too_big: 'Algunos archivos excedieron el tamaño máximo permitido',
            exceeds_by: 'Excede por',
            been_problem_in_some_files: 'Ocurrió un problema al ejecutar la acción en algunos archivos',
            files_updated: 'Archivos actualizados correctamente',
		},
		en: {
			welcome_to: 'Welcome to',
			login: 'Sign in',
			operation: 'Operation',
			user: 'User',
			password: 'Password',
			continue: 'Continue',
			enter_operation: 'Enter an operation',
			user_not_found: 'User or paswword is wrong.',
			password_incorrect: 'Incorrect password.',
			password_expired: 'Your current password has expired, please change it.',
			password_used_recently: 'The new password was recently used.',
			license_active: 'You must have at least one active license.',
			change_password_successfully: 'Password changed successfully.',
			password_incorrect_5: 'Incorrect password more than 5 times.',
			accept: 'Accept',
			cancel: 'Cancel',
			close: 'Close',
			refresh: 'Refresh',
			delete: 'Delete',
			download: 'Download',
			restore_password: 'Restore password',
			password_expired_change: 'Your password has expired, please enter a new one.',
			back: 'Back',
			password_new: 'New password',
			password_old: 'Old password',
			password_confirm: 'Confirm password',
			password_change: 'Change password',
			password_confirm_please: 'Please confirm your password correctly.',
			back_please_enter: 'Please go back and enter username and operation.',
			accept_logout: 'Are you sure you want to log out?',
			file_manager: 'File manager',
			audit_log: 'Audit log',
			documents: 'Documents',
			images: 'Images',
			videos: 'Videos',
			audios: 'Audios',
			others: 'Others',
			search: 'Search',
			action: 'Action',
			been_problem: 'An error occurred.',
			name: 'Name',
			date: 'Date',
			file_download: 'Download file',
			file_info: 'File information',
			file_upload: 'Upload file',
			file_upload_please: 'Please upload file',
			file_select: 'Select a file.',
			folder_select: 'Select a folder.',
			file_delete_confirm: 'Are you sure you want to delete the file?',
            files_delete_confirm: 'Are you sure you want to delete the files selected?',
			folder_access_confirm: 'You are about to give public access to the entire folder, are you sure you want to do this?',
            file_access_confirm: "You are about to give public access to this file. Are you sure you want to perform this action?",
			file_download_successfully: 'File downloaded successfully.',
			folder_create: 'Create folder',
			folder_new: 'New folder',
			folder_no_title: 'Untitled folder',
			size: 'Size',
			access: 'Access',
			updated_at: 'Last modification',
			public: 'Public',
			restricted: 'Restricted',
			give_public_access: 'Give public access',
			copy_public_url: 'Copy public URL',
			delete_successfully: 'Deleted successfully.',
			delete_90_days: 'The file can only be deleted after 90 days.',
			delete_90_days_folder: 'At least one file does not meet the 90-day restriction.',
			been_problem_delete_file: 'An error occurred while deleting the file.',
			file_upload_successfully: 'The file has been uploaded successfully.',
			been_problem_upload_file: 'There was a problem uploading the file.',
			file_name_not_empty: 'The folder name cannot be empty.',
			folder_create_successfully: 'Folder created successfully.',
			been_problem_create_file: 'There was a problem creating the folder.',
			date_ini: 'Start date',
			date_end: 'End date',
			has_uppercase: 'The password must contain at least one uppercase letter.',
			has_lowercase: 'The password must contain at least one lowercase letter.',
			has_number: 'The password must contain at least one number.',
			has_special_char: 'The password must contain at least one special character: $, %, &, @, *, #, ?',
			is_valid_length: 'The password length must be between 12 and 24 characters.',
            caution_uppercase: 'CAUTION',
            folder_access_successfully: 'Folder changed to public access successfully.',
            global_search_on: "Pressing, this search will apply only to the current directory",
            global_search_off: "Pressing, this search will apply to all directories.",
            longer_expected: "Please note that this process may take longer than expected.",
            restrict_access: "Restrict acceso",
            public_url_copied: "The URL was successfully copied to the clipboard!",
            invalid_url: "Invalid URL or unsupported format",
            add: "Add",
            selection_multiple: "Selection",
            select_files_to_download: "Please select the files to download",
            select_files_to_delete: "Please select the files to delete",
            file_newer_than_90_days: "The file cannot be deleted because it is newer than 90 days.",
            retry_delete: "Retry delete",
            files_selected: "Files selected",
            batch: "Batch",
            of: "of",
            files: "files",
            files_per_batch: "files per batch",
            delete_batch: "Delete batch",
            deleting: "Deleting",
            been_problem_download_file: "An error occurred while downloading the file.",
            longer_expected_download_file: "This process may take a few minutes.",
            drop_files_here: 'Drop files here or click to select',
            file_too_big: 'File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB',
            invalid_file_type: 'You cannot upload files of this type',
            upload_failed: 'Upload failed',
            max_files_exceeded: 'You have exceeded the maximum number of files',
            remove_file: 'Remove file',
            cancel_upload: 'Cancel upload',
            allowed_files: 'Allowed files',
            max_file_size: 'Maximum file size',
            max_files: 'Maximum number of simultaneous files',
            max_total_size: 'Maximum total size',
            upload: 'Upload',
            total: 'total',
            total_size_exceeded: 'Total file size exceeds the 150MB limit',
            uploaded_successfully: 'Files uploaded successfully',
            processing: 'Processing',
            some_files_too_big: 'Some files exceeded the maximum allowed size',
            exceeds_by: 'Exceeds by',
            been_problem_in_some_files: 'An error occurred while performing the action on some files',
            files_updated: 'Files updated successfully',
		},
		pt: {
			welcome_to: 'Bem-vindo a',
			login: 'Entrar',
			operation: 'Operação',
			user: 'Usuário',
			password: 'Senha',
			continue: 'Continuar',
			enter_operation: 'Digite uma operação',
			user_not_found: 'Login ou senha incorretos.',
			password_incorrect: 'Senha incorreta.',
			password_expired: 'Sua senha atual expirou, por favor, altere-a.',
			password_used_recently: 'A nova senha foi usada recentemente.',
			license_active: 'Você deve ter pelo menos uma licença ativa.',
			change_password_successfully: 'Senha alterada com sucesso.',
			password_incorrect_5: 'Senha incorreta mais de 5 vezes.',
			accept: 'Aceitar',
			cancel: 'Cancelar',
			close: 'Fechar',
			refresh: 'Atualizar',
			delete: 'Excluir',
			download: 'Baixar',
			restore_password: 'Restaurar senha',
			password_expired_change: 'Sua senha expirou, por favor, insira uma nova.',
			back: 'Voltar',
			password_new: 'Nova senha',
			password_old: 'Senha antiga',
			password_confirm: 'Confirmar senha',
			password_change: 'Alterar senha',
			password_confirm_please: 'Por favor, confirme sua senha corretamente.',
			back_please_enter: 'Por favor, volte e insira nome de usuário e operação.',
			accept_logout: 'Tem certeza de que deseja sair?',
			file_manager: 'Gerenciador de arquivos',
			audit_log: 'Log de auditoria',
			documents: 'Documentos',
			images: 'Imagens',
			videos: 'Vídeos',
			audios: 'Áudios',
			others: 'Outros',
			search: 'Buscar',
			action: 'Ação',
			been_problem: 'Ocorreu um problema.',
			name: 'Nome',
			date: 'Data',
			file_download: 'Baixar arquivo',
			file_info: 'Informações do arquivo',
			file_upload: 'Enviar arquivo',
			file_upload_please: 'Por favor, envie o arquivo',
			file_select: 'Selecione um arquivo.',
			folder_select: 'Selecione uma pasta.',
			file_delete_confirm: 'Tem certeza de que deseja excluir o arquivo?',
            files_delete_confirm: 'Tem certeza de que deseja excluir os arquivos selecionados?',
			folder_access_confirm: 'Você está prestes a dar acesso público a toda a pasta. Tem certeza de que deseja fazer isso?',
            file_access_confirm: "Você está prestes a conceder acesso público a este arquivo. Tem certeza de que deseja fazer isso?",
			file_download_successfully: 'Arquivo baixado com sucesso.',
			folder_create: 'Criar pasta',
			folder_new: 'Nova pasta',
			folder_no_title: 'Pasta sem título',
			size: 'Tamanho',
			access: 'Acesso',
			updated_at: 'Última modificação',
			public: 'Público',
			restricted: 'Restrito',
			give_public_access: 'Dar acesso público',
			copy_public_url: 'Copiar URL pública',
			delete_successfully: 'Excluído com sucesso.',
			delete_90_days: 'O arquivo só pode ser excluído após 90 dias.',
			delete_90_days_folder: 'Pelo menos um arquivo não atende à restrição de 90 dias.',
			been_problem_delete_file: 'Ocorreu um erro ao excluir o arquivo.',
			file_upload_successfully: 'O arquivo foi enviado com sucesso.',
			been_problem_upload_file: 'Houve um problema ao enviar o arquivo.',
			file_name_not_empty: 'O nome da pasta não pode estar vazio.',
			folder_create_successfully: 'Pasta criada com sucesso.',
			been_problem_create_file: 'Houve um problema ao criar a pasta.',
			date_ini: 'Data de início',
			date_end: 'Data de término',
			has_uppercase: 'A senha deve conter pelo menos uma letra maiúscula.',
			has_lowercase: 'A senha deve conter pelo menos uma letra minúscula.',
			has_number: 'A senha deve conter pelo menos um número.',
			has_special_char: 'A senha deve conter pelo menos um caractere especial permitido: $, %, &, @, *, #, ?',
			is_valid_length: 'O comprimento da senha deve estar entre 12 e 24 caracteres.',
            caution_uppercase: 'CUIDADO',
            folder_access_successfully: 'A pasta foi alterada com sucesso para acesso público.',
            global_search_on: "Pressionar, essa pesquisa se aplicará somente ao diretório atual",
            global_search_off: "Pressionar, essa pesquisa se aplicará a todos os diretórios.",
            longer_expected: "Observe que esse processo pode demorar mais do que o esperado.",
            restrict_access: "Acesso restrito",
            public_url_copied: "O URL foi copiado com sucesso para a área de transferência!",
            invalid_url: "URL inválido ou formato não suportado",
            add: "Adicionar",
            selection_multiple: "Seleção",
            select_files_to_download: "Por favor selecione os arquivos para download",
            select_files_to_delete: "Por favor selecione os arquivos para excluir",
            file_newer_than_90_days: "O arquivo não pode ser excluído porque tem menos de 90 dias.",
            retry_delete: "Reintentar exclusão",
            files_selected: "Arquivos selecionados",
            batch: "Lote",
            of: "de",
            files: "arquivos",
            files_per_batch: "arquivos por lote",
            delete_batch: "Excluir lote",
            deleting: "Excluindo",
            been_problem_download_file: "Ocorreu um erro ao baixar o arquivo.",
            longer_expected_download_file: "Este processo pode demorar alguns minutos.",
            drop_files_here: 'Arraste arquivos aqui ou clique para selecionar',
            file_too_big: 'O arquivo é muito grande ({{filesize}}MB). Tamanho máximo: {{maxFilesize}}MB',
            invalid_file_type: 'Você não pode fazer upload de arquivos deste tipo',
            upload_failed: 'Falha no upload',
            max_files_exceeded: 'Você excedeu o número máximo de arquivos',
            remove_file: 'Remover arquivo',
            cancel_upload: 'Cancelar upload',
            allowed_files: 'Arquivos permitidos',
            max_file_size: 'Tamanho máximo por arquivo',
            max_files: 'Número máximo de arquivos simultâneos',
            max_total_size: 'Tamanho máximo total',
            upload: 'Enviar',
            total: 'total',
            total_size_exceeded: 'O tamanho total dos arquivos excede o limite de 150MB',
            uploaded_successfully: 'Arquivos enviados com sucesso',
            processing: 'Processando',
            some_files_too_big: 'Alguns arquivos excederam o tamanho máximo permitido',
            exceeds_by: 'Excede por',
            been_problem_in_some_files: 'Ocorreu um erro ao executar a ação em alguns arquivos',
            files_updated: 'Arquivos atualizados com sucesso',
		}
	}
});

function getLanguage() {
  const ln = window.navigator.userLanguage || window.navigator.language;
  const langtag = ln.split("-");
  let language = langtag[0];

  if (language !== "es" && language !== "en" && language !== "pt") {
    language = 'en';
  }

  return language;
}

i18n.locale = getLanguage();

export default i18n;
