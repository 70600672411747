<template>
  <v-dialog v-model="dialogModel">
    <v-card>
      <v-card-title class="headline title-wrap">
        {{title}}
      </v-card-title>

      <v-card-text class="dialog-delete">
        <template v-if="shouldShowBatches">
          <div class="batch-info mb-4">
            <v-chip class="mr-2" color="primary" small>
              {{ currentBatchIndex + 1 }}/{{ totalBatches }} {{ $t('batch') }}
            </v-chip>
            <span class="caption">
              {{ startIndex + 1 }}-{{ Math.min(endIndex, items.length) }}
              {{ $t('of') }} {{ items.length }} {{ $t('files') }}
            </span>
          </div>
        </template>

        <v-list dense>
          <v-list-item v-for="(item, index) in itemsToDisplay" :key="index">
            <v-list-item-icon>
              <v-icon>description</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <div class="d-flex align-center">
          <template v-if="shouldShowBatches">
            <v-btn
              :disabled="currentBatchIndex === 0"
              icon
              small
              @click="previousBatch"
              class="mr-2"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              :disabled="currentBatchIndex >= totalBatches - 1"
              icon
              small
              @click="nextBatch"
              class="mr-4"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <p class="info-files-selected mb-0">
              {{ batchSize }} {{ $t('files_per_batch') }}
            </p>
          </template>
          <template v-else>
            <p class="info-files-selected mb-0">
              {{ items.length }} {{ $t('files_selected') }}
            </p>
          </template>
        </div>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="closeDialog">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="handleAction"
          :disabled="loading"
        >
          <template v-if="!loading">
            {{ shouldShowBatches ? actionText + ' ' + $t('batch') : actionText }}
          </template>
          <template v-else>
            {{ loadingText }}...
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BatchDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    actionText: {
      type: String,
      required: true
    },
    loadingText: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      batchSize: 30,
      currentBatchIndex: 0
    }
  },
  computed: {
    dialogModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    totalBatches() {
      return Math.ceil(this.items.length / this.batchSize)
    },
    startIndex() {
      return this.currentBatchIndex * this.batchSize
    },
    endIndex() {
      return Math.min(this.startIndex + this.batchSize, this.items.length)
    },
    currentBatchItems() {
      return this.items.slice(this.startIndex, this.endIndex)
    },
    shouldShowBatches() {
      return this.items.length > this.batchSize
    },
    itemsToDisplay() {
      return this.shouldShowBatches ? this.currentBatchItems : this.items
    }
  },
  methods: {
    closeDialog() {
      this.currentBatchIndex = 0
      this.$emit('close')
    },
    nextBatch() {
      if (this.currentBatchIndex < this.totalBatches - 1) {
        this.currentBatchIndex++
      }
    },
    previousBatch() {
      if (this.currentBatchIndex > 0) {
        this.currentBatchIndex--
      }
    },
    handleAction() {
      this.$emit('action', {
        items: this.currentBatchItems,
        isBatch: this.shouldShowBatches,
        currentBatchIndex: this.currentBatchIndex,
        totalBatches: this.totalBatches,
        nextBatch: () => {
          if (this.currentBatchIndex < this.totalBatches - 1) {
            this.currentBatchIndex++;
            return true;
          }
          return false;
        }
      })
    }
  },
  watch: {
    value(newVal) {
      if (!newVal) {
        this.currentBatchIndex = 0
      }
    }
  }
}
</script>

<style scoped>
.info-files-selected {
  font-size: 12px;
  color: #797979;
  margin-bottom: 0px;
}

.title-wrap {
  white-space: normal;
  word-wrap: break-word;
  word-break: keep-all;
  overflow-wrap: break-word;
}

.dialog-delete {
  height: calc(100vh - 220px);
  width: calc(100vw - 70px);
  overflow: auto !important;

  @media (max-width: 600px) {
    height: calc(100vh - 350px);
  }
}

.dialog-delete::-webkit-scrollbar {
  -webkit-appearance: none;
}

.dialog-delete::-webkit-scrollbar:vertical {
  width: 10px;
}

.dialog-delete::-webkit-scrollbar-button:increment,
.dialog-delete::-webkit-scrollbar-button {
  display: none;
}

.dialog-delete::-webkit-scrollbar:horizontal {
  height: 10px;
}

.dialog-delete::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.dialog-delete::-webkit-scrollbar-track {
  border-radius: 10px;
}
</style>
