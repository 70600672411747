<template>
  <div>
    <div class="container-action-buttons">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="sucess" v-bind="attrs" v-on="on" fab class="ml-3 btn-navegation" x-small @click="deleteDialog = true" elevation="0" style="display: inline-flex; align-items: center;">
            <v-icon v-if="!loadingDelete" color="white">delete</v-icon>
            <v-progress-circular v-else class="progress-no-spacing" indeterminate color="white" size="16" width="2"></v-progress-circular>
          </v-btn>
        </template>
        <span>{{$t('delete')}}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="accent" v-bind="attrs" v-on="on" @click="downloadSelection" fab class="ml-3 btn-navegation" x-small elevation="0" style="display: inline-flex; align-items: center;">
            <v-icon v-if="!loadingDownload" color="white">download</v-icon>
            <v-progress-circular v-else class="progress-no-spacing" indeterminate color="white" size="16" width="2"></v-progress-circular>
          </v-btn>
        </template>
        <span>{{$t('download')}}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="info" v-bind="attrs" v-on="on" @click="changeAccessFilesToPublic" fab class="ml-3 btn-navegation" x-small elevation="0" style="display: inline-flex; align-items: center;">
            <v-icon v-if="!loadingPublicAccess" color="white">lock_open</v-icon>
            <v-progress-circular v-else class="progress-no-spacing" indeterminate color="white" size="16" width="2"></v-progress-circular>
          </v-btn>
        </template>
        <span>{{$t('give_public_access')}}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="warning" v-bind="attrs" v-on="on" @click="changeAccessFilesToRestricted" fab class="ml-3 btn-navegation" x-small elevation="0" style="display: inline-flex; align-items: center;">
            <v-icon v-if="!loadingRestrictedAccess" color="white">lock</v-icon>
            <v-progress-circular v-else class="progress-no-spacing" indeterminate color="white" size="16" width="2"></v-progress-circular>
          </v-btn>
        </template>
        <span>{{$t('restrict_access')}}</span>
      </v-tooltip>
    </div>

    <!-- Diálogos -->
    <batch-dialog
      v-model="deleteDialog"
      :items="itemsSelected"
      :title="$t('files_delete_confirm')"
      :action-text="$t('delete')"
      :loading-text="$t('deleting')"
      :loading="loadingDelete"
      @close="closeDeleteDialog(false)"
      @action="handleDeleteAction"
    />

    <batch-dialog
      v-model="publicAccessDialog"
      :items="itemsSelected"
      :title="$t('folder_access_confirm')"
      :action-text="$t('give_public_access')"
      :loading-text="$t('processing')"
      :loading="loadingPublicAccess"
      @close="closePublicAccessDialog(false)"
      @action="handlePublicAccessAction"
    />

    <batch-dialog
      v-model="restrictedAccessDialog"
      :items="itemsSelected"
      :title="$t('file_access_confirm')"
      :action-text="$t('restrict_access')"
      :loading-text="$t('processing')"
      :loading="loadingRestrictedAccess"
      @close="closeRestrictedAccessDialog(false)"
      @action="handleRestrictedAccessAction"
    />
  </div>
</template>

<script>
import sharedMethods from '../../mixins/sharedMethods.js'
import BatchDialog from './BatchDialog.vue'

export default {
  name: 'ActionSelection',
  components: {
    BatchDialog
  },
  mixins: [sharedMethods],
  props: {
    itemsSelected: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      default: ''
    }
  },
  computed: {
    totalBatches() {
      return Math.ceil(this.itemsSelected.length / this.batchSize)
    },
    startIndex() {
      return this.currentBatchIndex * this.batchSize
    },
    endIndex() {
      return Math.min(this.startIndex + this.batchSize, this.itemsSelected.length)
    },
    currentBatchItems() {
      return this.itemsSelected.slice(this.startIndex, this.endIndex)
    },
    failedItems() {
      return this.itemsSelected.filter(item =>
        this.deleteResults[item.name]?.status === 'error'
      );
    },
    hasFailedItems() {
      return Object.values(this.deleteResults).some(result => result.status === 'error');
    },
    itemsToShow() {
      return this.hasAttemptedDeletion ?
        this.itemsSelected.filter(item => this.deleteResults[item.name]?.status === 'error') :
        this.itemsSelected;
    },
    shouldShowBatches() {
      return this.itemsSelected.length > this.batchSize;
    },
    itemsToDisplay() {
      return this.shouldShowBatches ? this.currentBatchItems : this.itemsSelected;
    }
  },
  data() {
    return {
      deleteDialog: false,
      publicAccessDialog: false,
      restrictedAccessDialog: false,
      loadingDownload: false,
      loadingDelete: false,
      loadingPublicAccess: false,
      loadingRestrictedAccess: false,
      deleteResults: {},
      hasAttemptedDeletion: false,
      batchSize: 30,
      currentBatchIndex: 0
    }
  },
  methods: {
    closeDeleteDialog(refresh = true) {
      this.deleteDialog = false;
      this.deleteResults = {};
      this.hasAttemptedDeletion = false;
      this.currentBatchIndex = 0;
      if(refresh) this.$emit('refreshFiles');
    },
    closePublicAccessDialog(refresh = true) {
      this.publicAccessDialog = false;
      this.currentBatchIndex = 0;
      if(refresh) this.$emit('refreshFiles');
    },
    closeRestrictedAccessDialog(refresh = true) {
      this.restrictedAccessDialog = false;
      this.currentBatchIndex = 0;
      if(refresh) this.$emit('refreshFiles');
    },
    nextBatch() {
      if (this.currentBatchIndex < this.totalBatches - 1) {
        this.currentBatchIndex++;
      }
    },
    previousBatch() {
      if (this.currentBatchIndex > 0) {
        this.currentBatchIndex--;
      }
    },
    async handleDeleteAction({ items, isBatch, currentBatchIndex, totalBatches, nextBatch }) {
      if (items.length === 0) {
        this.$emit('showSnackbarError', this.$t('select_files_to_delete'))
        return;
      }

      this.loadingDelete = true;

      let formData = new FormData()
      formData.append("username", this.$store.getters.globalInfo.username);
      formData.append("operation", this.$store.getters.globalInfo.operation);
      formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
      formData.append("file_name", items.map(item => item.fullName));
      formData.append("action", 'deleteFile');

      try {
        const result = await this.axios.post(this.baseUrl + 'server/controller.php', formData);

        if(result.data.code === "200" && result.data.msg?.files) {
          const successfullFiles = result.data.msg.files
            .filter(file => file.code === "200")
            .map(file => file.name);

          this.$emit('files-deleted', successfullFiles);

          if (isBatch && successfullFiles.length === items.length) {
            if (!nextBatch()) {
              this.closeDeleteDialog();
            }
          } else if (!isBatch) {
            this.closeDeleteDialog();
          }
        }
      } catch (error) {
        console.error(error);
        this.$emit('showSnackbarError', this.$t('been_problem'));
      } finally {
        this.loadingDelete = false;
      }
    },
    async handlePublicAccessAction({ items, isBatch, currentBatchIndex, totalBatches, nextBatch }) {
      if (items.length === 0) {
        this.$emit('showSnackbarError', this.$t('select_files_to_download'))
        return;
      }

      this.loadingPublicAccess = true;

      let formData = new FormData()
      formData.append("username", this.$store.getters.globalInfo.username);
      formData.append("operation", this.$store.getters.globalInfo.operation);
      formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
      formData.append("files", items.map(item => item.fullName));
      formData.append("action", 'changeAccessFilesToPublic');

      try {
        const result = await this.axios.post(this.baseUrl + 'server/controller.php', formData);

        if(result.data.code === "200" && result.data.msg?.files) {
          const errorFiles = result.data.msg.files
            .filter(file => file.code !== "200")
            .map(file => file.name);

          const updatedFiles = result.data.msg.files
            .filter(file => file.code === "200")
            .map(file => file.name);

          this.$emit('files-updated', updatedFiles);

          if (isBatch && updatedFiles.length === items.length) {
            if (!nextBatch()) {
              this.closePublicAccessDialog();
            }
          } else if (!isBatch) {
            this.$emit('showSnackbarError', (errorFiles.length > 0) ? this.$t('been_problem_in_some_files') : this.$t('files_updated'));
            this.closePublicAccessDialog();
          }
        }
      } catch (error) {
        console.error(error);
        this.$emit('showSnackbarError', this.$t('been_problem'));
      } finally {
        this.loadingPublicAccess = false;
      }
    },
    async handleRestrictedAccessAction({ items, isBatch, currentBatchIndex, totalBatches, nextBatch }) {
      if (items.length === 0) {
        this.$emit('showSnackbarError', this.$t('select_files_to_download'))
        return;
      }

      this.loadingRestrictedAccess = true;

      let formData = new FormData()
      formData.append("username", this.$store.getters.globalInfo.username);
      formData.append("operation", this.$store.getters.globalInfo.operation);
      formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
      formData.append("files", items.map(item => item.fullName));
      formData.append("action", 'changeAccessFilesToRestricted');

      try {
        const result = await this.axios.post(this.baseUrl + 'server/controller.php', formData);

        if(result.data.code === "200" && result.data.msg?.files) {
          const errorFiles = result.data.msg.files
            .filter(file => file.code !== "200")
            .map(file => file.name);

          const updatedFiles = result.data.msg.files
            .filter(file => file.code === "200")
            .map(file => file.name);

          this.$emit('files-updated', updatedFiles);

          if (isBatch && updatedFiles.length === items.length) {
            if (!nextBatch()) {
              this.closeRestrictedAccessDialog();
            }
          } else if (!isBatch) {
            this.$emit('showSnackbarError', (errorFiles.length > 0) ? this.$t('been_problem_in_some_files') : this.$t('files_updated'));
            this.closeRestrictedAccessDialog();
          }
        }
      } catch (error) {
        console.error(error);
        this.$emit('showSnackbarError', this.$t('been_problem'));
      } finally {
        this.loadingRestrictedAccess = false;
      }
    },
    changeAccessFilesToPublic() {
      if(this.itemsSelected.length === 0) {
        this.$emit('showSnackbarError', this.$t('select_files_to_download'))
        return
      }
      this.publicAccessDialog = true;
    },
    changeAccessFilesToRestricted() {
      if(this.itemsSelected.length === 0) {
        this.$emit('showSnackbarError', this.$t('select_files_to_download'))
        return
      }
      this.restrictedAccessDialog = true;
    },
    downloadSelection() {
      if(this.itemsSelected.length === 0) {
        this.$emit('showSnackbarError', this.$t('select_files_to_download'))
        return
      }

      this.loadingDownload = true;
      const formData = new FormData();
      formData.append("username", this.$store.getters.globalInfo.username);
      formData.append("operation", this.$store.getters.globalInfo.operation);
      formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
      formData.append("file_name", this.itemsSelected.map(item => item.fullName));
      formData.append("action", 'downloadFile');
      const url = this.baseUrl + 'server/controller.php';

      if(this.itemsSelected.length > 25) {
        this.$emit('showSnackbarError', this.$t('longer_expected_download_file'))
      }

      this.axios.post(url, formData, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(async (response) => {
        const contentType = response.headers['content-type'];
        if (contentType && contentType.includes('application/zip')) {
          await this.handleZipDownload(response.data);
        } else {
          await this.handleIndividualDownloads(response.data);
        }
      })
      .catch(this.handleDownloadError)
      .finally(() => {
        this.loadingDownload = false;
      });
    },

    async handleZipDownload(data) {
      try {
        const date = new Date();
        const blob = new Blob([data], {
          type: 'application/zip'
        });

        // Verificar que el blob no esté vacío
        if (blob.size === 0) {
          throw new Error('Generated ZIP is empty');
        }

        const filename = `storage-${this.$store.getters.globalInfo.operation}-${date.getTime()}.zip`;
        await this.downloadBlob(blob, filename);
      } catch (error) {
        console.error('Error in handleZipDownload:', error);
        this.snackbarErrorMessage = this.$t('been_problem_download_file');
        this.snackbarError = true;
      }
    },

    async handleIndividualDownloads(data) {
      const result = this.parseArrayBufferResponse(data);

      if(result.code == '200' && Array.isArray(result.msg?.files)) {
        const { successFiles, failedFiles } = this.categorizeFiles(result.msg.files);

        for (const file of successFiles) {
          await this.downloadFile(file);
        }
      } else {
        throw new Error(this.$t('been_problem_download_file'));
      }
    },

    parseArrayBufferResponse(data) {
      const decoder = new TextDecoder('utf-8');
      return JSON.parse(decoder.decode(data));
    },

    categorizeFiles(files) {
      return {
        successFiles: files.filter(file => !file.error),
        failedFiles: files.filter(file => file.error)
      };
    },

    handleDownloadError(error) {
      console.error(error);
      if (error.response?.data instanceof ArrayBuffer) {
        try {
          const errorData = this.parseArrayBufferResponse(error.response.data);
          this.$emit('showSnackbarError', errorData.message || this.$t('been_problem'));
        } catch {
          this.$emit('showSnackbarError', error.message || this.$t('been_problem'));
        }
      } else {
        this.$emit('showSnackbarError', error.message || this.$t('been_problem'));
      }
    },

    async downloadFile(file) {
      try {
        if (file.error) {
          console.error(`Error downloading file ${file.name}: ${file.message}`);
          return;
        }

        const blob = this.createBlobFromBase64(file.file);
        await this.downloadBlob(blob, file.name);

      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error);
        this.$emit('showSnackbarError', this.$t('been_problem_download_file'));
      }
    },

    createBlobFromBase64(base64Content) {
      const byteCharacters = atob(base64Content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: 'application/octet-stream' });
    },

    async downloadBlob(blob, filename) {
      try {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);

        // Agregar un pequeño delay antes de hacer click
        await new Promise(resolve => setTimeout(resolve, 100));

        link.click();

        // Esperar un poco antes de limpiar
        await new Promise(resolve => setTimeout(resolve, 100));

        window.URL.revokeObjectURL(url);
        link.remove();
      } catch (error) {
        console.error('Error in downloadBlob:', error);
        throw error;
      }
    },
  }
}
</script>

<style scoped>
.info-files-selected {
  font-size: 12px;
  color: #797979;
  margin-bottom: 0px;
}

.container-action-buttons {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
}

.title-wrap {
  white-space: normal;
  word-wrap: break-word;
  word-break: keep-all;
  overflow-wrap: break-word;
}

.dialog-delete {
  height: calc(100vh - 220px);
  width: calc(100vw - 70px);
  overflow: auto !important;

  @media (max-width: 600px) {
    height: calc(100vh - 350px);
  }
}

.dialog-delete::-webkit-scrollbar {
  -webkit-appearance: none;
}

.dialog-delete::-webkit-scrollbar:vertical {
  width: 10px;
}

.dialog-delete::-webkit-scrollbar-button:increment,
.dialog-delete::-webkit-scrollbar-button {
  display: none;
}

.dialog-delete::-webkit-scrollbar:horizontal {
  height: 10px;
}

.dialog-delete::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.dialog-delete::-webkit-scrollbar-track {
  border-radius: 10px;
}

.progress-no-spacing {
  padding: 0 !important;
  margin: 0 !important;
}

</style>
