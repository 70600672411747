import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import i18n from './i18n';

Vue.config.productionTip = false

Vue.prototype.axios = axios

// Obtener el host actual automáticamente
const currentHost = window.location.hostname;
const baseUrl = currentHost === 'localhost' ? 'https://localhost/' : `https://${currentHost}/`;

Vue.prototype.baseUrl = baseUrl;

// Configuración global de Axios
axios.interceptors.request.use(config => {
  const token = store.getters.globalInfo?.tokenAccess;

  if (config.headers['Content-Type'] === 'multipart/form-data') {
    if (process.env.NODE_ENV === 'production') {
      config.headers = {
        ...config.headers,
        "Content-Type": "multipart/form-data",
        Authorization: token ? `Bearer ${token}` : '',
        withCredentials: true
      };
    } else {
      config.headers = {
        ...config.headers,
        "Content-Type": "multipart/form-data",
        authorization: token ? `Bearer ${token}` : '',
        "Cache-Control": "no-cache"
      };
    }
  } else {
    // Para otras peticiones que no son multipart/form-data
    if (process.env.NODE_ENV === 'production') {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    } else {
      config.headers.authorization = token ? `Bearer ${token}` : '';
    }
  }
  return config;
}, error => {
  return Promise.reject(error);
});

Vue.prototype.axios = axios

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  i18n,
}).$mount('#app')
