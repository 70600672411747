<template>
    <div>
      <div class="container-action-buttons">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="sucess" v-bind="attrs" v-on="on" fab class="btn-navegation" x-small @click="$emit('goBack')" elevation="0"><v-icon color="white">arrow_back</v-icon></v-btn>
          </template>
          <span>{{$t('back')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="accent" v-bind="attrs" v-on="on" fab class="ml-3 btn-navegation" x-small @click="$emit('changeAdd')" elevation="0"><v-icon color="white">{{ showAdd ? 'close' : 'add' }}</v-icon></v-btn>
          </template>
          <span>{{showAdd ? $t('close') : $t('add')}}</span>
        </v-tooltip>
        <v-expand-transition>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-show="showAdd" color="info" v-bind="attrs" v-on="on" fab class="ml-3 btn-navegation" x-small @click="openFileInput()" elevation="0"><v-icon color="white">description</v-icon></v-btn>
            </template>
            <span>{{$t('file_upload')}}</span>
          </v-tooltip>
        </v-expand-transition>
        <v-expand-transition>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-show="showAdd" color="warning" v-bind="attrs" v-on="on" fab class="ml-3 btn-navegation" x-small @click="dialogCreateFolder = true;" elevation="0"><v-icon color="white">folder</v-icon></v-btn>
            </template>
            <span>{{$t('folder_create')}}</span>
          </v-tooltip>
        </v-expand-transition>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="info" v-bind="attrs" v-on="on" fab class="ml-3 btn-navegation" x-small @click="$emit('refreshFiles')" elevation="0"><v-icon color="white">refresh</v-icon></v-btn>
          </template>
          <span>{{$t('refresh')}}</span>
        </v-tooltip>
        <div v-if="buttonSelectionMultiple">
          <v-btn outlined color="info" class="ml-3" small @click="$emit('selectionMultiple')" elevation="0">{{selection ? $t('cancel') : $t('selection_multiple')}}</v-btn>
        </div>
      </div>
      <v-dialog v-model="dialogCreateFolder" max-width="400" persistent>
        <v-card class="scroll">
          <v-card-title><p class="text-dialog-confirm mb-0">{{ $t('folder_new') }}</p></v-card-title>
          <v-card-text class="mb-1">
            <div class="ma-2 mb-4">
                <v-text-field outlined dense :label="$t('folder_no_title')" v-model="nameFolder"></v-text-field>
            </div>
            <v-row justify="end">
              <div>
                <v-btn color="#C00AC0" @click="createFolder()" class="mr-3 white--text">{{$t('accept')}}</v-btn>
                <v-btn outlined color="#061A40" @click="dialogCreateFolder = false;">{{$t('cancel')}}</v-btn>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogUpload" persistent max-width="60%">
        <v-card class="scroll">
          <v-card-title class="headline d-flex justify-space-between align-center">
            {{$t('file_upload')}}
            <v-btn icon @click="closeUploadDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="dialog-upload">
            <div
              class="upload-zone pa-4"
              :class="{ 'dragover': isDragOver }"
              @dragenter.prevent="isDragOver = true"
              @dragover.prevent="isDragOver = true"
              @dragleave.prevent="isDragOver = false"
              @drop.prevent="handleDrop"
              @click="$refs.fileInput.click()"
            >
              <div class="text-center">
                <v-icon size="48" color="primary">mdi-cloud-upload</v-icon>
                <div class="text-h6 mt-2">{{$t('drop_files_here')}}</div>
                <div class="text-caption">{{$t('max_file_size')}}: {{ maxSizeFileInMB }}MB</div>
              </div>
            </div>

            <v-list v-if="selectedFiles.length > 0" class="mt-4" two-line>
              <v-list-item v-for="(file, index) in selectedFiles" :key="index">
                <v-list-item-avatar>
                  <v-icon>{{ getFileIcon(file.type) }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ file.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ formatFileSize(file.size) }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon small @click="removeFile(index)">
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div class="mt-4">
              <h3>{{$t('allowed_files')}}:</h3>
              <v-chip-group column>
                <v-chip small color="primary" outlined>{{$t('images')}}: jpg, jpeg, png, svg</v-chip>
                <v-chip small color="primary" outlined>{{$t('audios')}}: mp3, wav, gsm</v-chip>
                <v-chip small color="primary" outlined>{{$t('videos')}}: mp4, avi</v-chip>
                <v-chip small color="primary" outlined>{{$t('documents')}}: pdf, csv, json</v-chip>
              </v-chip-group>
              <div class="mt-2 caption">
                {{$t('max_total_size')}}: 150MB {{$t('total')}}
              </div>
            </div>

            <input
              ref="fileInput"
              type="file"
              multiple
              style="display: none"
              accept=".jpg,.jpeg,.png,.svg,.mp3,.wav,.gsm,.mp4,.avi,.pdf,.csv,.json"
              @change="handleFileSelect"
            >
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="d-flex align-center">
              <p class="info-files-selected mb-0">
                {{uppercaseFirstLetter($t('files'))}}: <span class="font-weight-bold">{{ selectedFiles.length }}</span> - {{uppercaseFirstLetter($t('total'))}}: <span class="font-weight-bold">{{ currentTotalSizeInMB }} MB</span>
              </p>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="selectedFiles.length === 0 || uploading || isUploadDisabled"
              :loading="uploading"
              @click="uploadFiles"
            >
              {{$t('upload')}} ({{ selectedFiles.length }})
            </v-btn>
            <v-btn text @click="closeUploadDialog">{{$t('cancel')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>
<script>
import sharedMethods from '../../mixins/sharedMethods.js'

export default {
  name: 'ActionButtonsComponent',
  mixins: [sharedMethods],
  props:{
    path: {type: String, default: ''},
    showAdd: {type: Boolean, default: false},
    buttonSelectionMultiple: {type: Boolean, default: false},
    selection: {type: Boolean, default: false},
  },
  data(){
    return {
      fab: false,
      dialogCreateFolder: false,
      dialogUpload: false,
      nameFolder: '',
      isDragOver: false,
      selectedFiles: [],
      uploading: false,
      uploadingProgress: 0,
      maxTotalSize: 150 * 1024 * 1024, // 150MB
      maxSizeFile: 15 * 1024 * 1024, // 15MB
      maxFiles: 150,
      exceedsSizeLimit: false
    }
  },
  watch: {
  },
  computed: {
    currentTotalSize() {
      return this.selectedFiles.reduce((acc, file) => acc + file.size, 0);
    },
    currentTotalSizeInMB() {
      try {
        return (this.currentTotalSize / 1024 / 1024).toFixed(2);
      } catch (error) {
        return 0;
      }
    },
    maxSizeFileInMB() {
      try {
        return (this.maxSizeFile / 1024 / 1024).toFixed(0);
      } catch (error) {
        return 0;
      }
    },
    isUploadDisabled() {
      return this.selectedFiles.length === 0 ||
             this.uploading ||
             this.selectedFiles.length > this.maxFiles ||
             this.currentTotalSize > this.maxTotalSize ||
             this.exceedsSizeLimit;
    }
  },
  created() {

  },mounted(){

  },methods: {
    uppercaseFirstLetter(string) {
      try {
        return string.charAt(0).toUpperCase() + string.slice(1);
      } catch (error) {
        return string;
      }
    },
    openFileInput() {
      this.dialogUpload = true;
    },
    closeUploadDialog() {
      this.dialogUpload = false;
      this.selectedFiles = [];
      this.uploading = false;
      this.uploadingProgress = 0;
    },
    handleDrop(e) {
      this.isDragOver = false;
      const droppedFiles = Array.from(e.dataTransfer.files);
      this.addFiles(droppedFiles);
    },
    handleFileSelect(e) {
      const selectedFiles = Array.from(e.target.files);
      this.addFiles(selectedFiles);
      e.target.value = ''; // Reset input
    },
    addFiles(files) {
      let filesNotAllowedForSize = false;
      const allowedTypes = [
        // Imágenes
        'image/jpeg', 'image/jpg', 'image/pjpeg',
        'image/png', 'image/x-png',
        'image/svg+xml',
        // Audio
        'audio/mpeg', 'audio/mp3', 'audio/x-mpeg', 'audio/mpeg3', 'audio/x-mpeg3',
        'audio/wav', 'audio/wave', 'audio/x-wav', 'audio/vnd.wave', 'audio/x-pn-wav',
        'audio/x-gsm', 'audio/gsm', 'application/x-gsm', 'application/octet-stream',
        // Video
        'video/mp4', 'video/x-mp4', 'application/mp4',
        'video/x-msvideo', 'video/avi', 'video/msvideo', 'application/x-troff-msvideo',
        // Documentos
        'application/pdf', 'application/x-pdf',
        'text/csv', 'application/csv', 'application/vnd.ms-excel', 'text/x-csv',
        'application/json', 'text/json'
      ];

      // Verificar si ya se alcanzó el límite de archivos
      if (this.selectedFiles.length + files.length > this.maxFiles) {
        this.$emit('showSnackbarError', `${this.$t('max_files_exceeded')} (${this.maxFiles})`);
        return;
      }

      // Agregar los archivos y validar tipos
      for (const file of files) {
        const extension = file.name.split('.').pop().toLowerCase();
        let processedFile = new File([file], file.name, {
          type: file.type || (extension === 'gsm' ? 'audio/x-gsm' : ''),
          lastModified: file.lastModified
        });

        // Validar tipo de archivo
        const isGsmFile = extension === 'gsm';
        if (!isGsmFile && !allowedTypes.includes(processedFile.type)) {
          console.log('File type not allowed:', processedFile.type, 'for file:', processedFile.name);
          this.$emit('showSnackbarError', `${processedFile.name}: ${this.$t('invalid_file_type')}`);
          continue;
        }

        console.log({processedFile: processedFile.size, maxSizeFile: this.maxSizeFile});
        if (processedFile.size > this.maxSizeFile) {
          filesNotAllowedForSize = true;
          continue;
        }

        // Agregar archivo si no está duplicado
        if (!this.selectedFiles.some(f => f.name === processedFile.name)) {
          this.selectedFiles.push(processedFile);
        }
      }

      if (filesNotAllowedForSize) {
        this.$emit('showSnackbarError', `${this.$t('some_files_too_big')}`);
      }

      // Actualizar el estado de exceedsSizeLimit y mostrar mensaje si es necesario
      this.exceedsSizeLimit = this.currentTotalSize > this.maxTotalSize;
      if (this.exceedsSizeLimit) {
        const exceededBy = ((this.currentTotalSize - this.maxTotalSize) / (1024 * 1024)).toFixed(2);
        this.$emit('showSnackbarError', `${this.$t('total_size_exceeded')} (${this.$t('exceeds_by')} ${exceededBy}MB)`);
      }
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
      this.exceedsSizeLimit = this.currentTotalSize > this.maxTotalSize;
    },
    getFileIcon(type) {
      if (type.startsWith('image/')) return 'mdi-file-image';
      if (type.startsWith('audio/')) return 'mdi-file-music';
      if (type.startsWith('video/')) return 'mdi-file-video';
      if (type === 'application/pdf') return 'mdi-file-pdf';
      if (type === 'text/csv') return 'mdi-file-delimited';
      return 'mdi-file';
    },
    formatFileSize(bytes) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },
    async uploadFiles() {
      this.uploading = true;

      try {
        const formData = new FormData();

        // Agregar cada archivo individualmente
        this.selectedFiles.forEach(file => {
          formData.append('file[]', file, file.name);
        });

        // Agregar los parámetros adicionales
        formData.append("action", 'uploadFile');
        formData.append("username", this.$store.getters.globalInfo.username);
        formData.append("operation", this.$store.getters.globalInfo.operation);
        formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
        formData.append("path", this.path);

        const url = this.baseUrl + 'server/controller.php';

        const result = await this.axios.post(url, formData);

        if (result.data.code == '200') {
          this.$emit('showSnackbarError', this.$t('uploaded_successfully'));
        } else {
          this.$emit('showSnackbarError', result.data.msg || this.$t('been_problem_upload_file'));
        }
      } catch (error) {
        console.error('Upload error:', error);
        this.$emit('showSnackbarError', this.$t('been_problem_upload_file'));
      } finally {
        this.uploading = false;
        this.closeUploadDialog();
        this.$emit('refreshFiles');
      }
    },
    async createFolder(){
        this.nameFolder = this.nameFolder.trim();
        this.nameFolder = this.nameFolder.replace(/[\s]/g, '_');
        if (this.nameFolder == '') {
            this.$emit('showSnackbarError', this.$t('file_name_not_empty'))
            return;
        }

        this.dialogCreateFolder = false;

        let formData = new FormData()
        formData.append("username", this.$store.getters.globalInfo.username);
        formData.append("operation", this.$store.getters.globalInfo.operation);
        formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
        formData.append("action", 'createFolder');
        formData.append("folder_name", this.nameFolder);
        formData.append("path", this.path);
        let headers = {};
        if (process.env.NODE_ENV === 'production') {
          headers = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`
            },
            withCredentials: true
          }
        } else {
          //utilizar para desarrollo(localhost)
        headers = {
          headers:{
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          }
        }
        }

        let url = this.baseUrl + 'server/controller.php';
        await this.axios.post(url, formData, headers).then(async (result) => {
            if(result.data.code == '200'){
                this.$emit('showSnackbarError', this.$t('folder_create_successfully'))
                this.$emit('changeAdd');
            }else{
                this.$emit('showSnackbarError', this.$t('been_problem_create_file'))
            }
            this.$emit('refreshFiles');
        })
        .catch((error) => {
            console.error(error)
            this.loadingFiles = false;
            this.$emit('showSnackbarError', this.$t('been_problem_create_file'))
        })
        this.nameFolder = '';
    }
  }
}
</script>
<style scoped>
.info-files-selected {
  font-size: 12px;
  color: #797979;
  margin-bottom: 0px;
}

.container-action-buttons {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
}

.upload-zone {
  border: 2px dashed rgba(192, 10, 192, 0.4);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-zone:hover {
  border-color: #C00AC0;
  background-color: rgba(192, 10, 192, 0.05);
}

.upload-zone.dragover {
  border-color: #C00AC0;
  background-color: rgba(192, 10, 192, 0.1);
}

.dialog-upload {
  height: calc(100vh - 220px);
  overflow: auto !important;


  @media (min-height: 1000px) {
    height: calc(100vh - 330px);
  }

  @media (max-width: 600px) {
    height: calc(100vh - 350px);
  }
}

.dialog-upload::-webkit-scrollbar {
  -webkit-appearance: none;
}

.dialog-upload::-webkit-scrollbar:vertical {
  width: 10px;
}

.dialog-upload::-webkit-scrollbar-button:increment,
.dialog-upload::-webkit-scrollbar-button {
  display: none;
}

.dialog-upload::-webkit-scrollbar:horizontal {
  height: 10px;
}

.dialog-upload::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.dialog-upload::-webkit-scrollbar-track {
  border-radius: 10px;
}

.font-weight-bold {
  font-weight: bold;
}
</style>
